import { convertArea } from "@turf/helpers";

import { publicRuntimeConfig } from "app-config";

import { calculateArea } from "shared/lib/geo/calculate-area";

import { Aoi, ModelId, ModelOptionId, Schema } from "types";

import { TModelByOption, TOptionByModel } from "./types";

const {
  BUILDINGS_HEIGHTS_MIN_AREA: buildingsHeightsMinArea,
  FOREST_HEIGHTS_MIN_AREA: forestHeightsMinArea,
} = publicRuntimeConfig;

export const ToggleModelOption = (optionsIds: string[], optionId: string) =>
  optionsIds.some((id) => optionId === id)
    ? optionsIds.filter((id) => id !== optionId)
    : optionsIds.concat(optionId);

export const ClearModelOptions = (
  optionsIds: ModelOptionId[],
  notAllowedOptionsIds: ModelOptionId[]
) => optionsIds.filter((id) => notAllowedOptionsIds.indexOf(id) === -1);

export const createOptionFilter =
  (modelId: ModelId, modelByOption: TModelByOption) =>
  (optionId: ModelOptionId) =>
    modelByOption && modelByOption[optionId].includes(modelId);

export function getNotAlowedOptions(aoi: Aoi): ModelOptionId[] {
  const notAllowed = [];
  const areaInMeters = calculateArea(aoi);

  if (areaInMeters > 0) {
    const area = convertArea(areaInMeters, "meters", "kilometers");

    if (area < buildingsHeightsMinArea)
      notAllowed.push(ModelOptionId.BuildingsHeights);
    if (area < forestHeightsMinArea)
      notAllowed.push(ModelOptionId.ForestHeights);
  }

  return notAllowed;
}

// export const reduceOptionsByModel = (schema: Schema) =>
//   schema.models.reduce(
//     (acc, { id }) => ({
//       ...acc,
//       [id]: schema.modelOptions.filter((option) =>
//         option.modelIds.includes(id)
//       ),
//     }),
//     {} as TOptionByModel
//   );

export const reduceModelsOption = (schema: Schema) =>
  schema.modelOptions.reduce(
    (acc, { id, modelIds }) => ({
      ...acc,
      [id]: modelIds,
    }),
    {} as TModelByOption
  );

export const fallbackModels: TModelByOption = {
  buildingsHeights: [],
  classification: [],
  forestHeights: [],
  osm: [],
  simplification: [],
  sam14: [],
  sam16: [],
  sam18: [],
  samAero: [],
};
