/**
 * Models
 */

import { Geometry } from "@turf/helpers";

export type Range<Min = number, Max = Min> = { min: Min; max?: Max };

export enum ModelId {
  DenseBuildings = "dense-buildings",
  Buildings = "buildings",
  Forest = "forest",
  Roads = "roads",
  Constructions = "constructions",
  Fields = "fields",
  UavBuildings = "uav-buildings",
  SAM = "sam",
}

export enum ModelOptionId {
  Classification = "classification",
  BuildingsHeights = "buildingsHeights",
  ForestHeights = "forestHeights",
  Simplification = "simplification",
  Osm = "osm",
  SAM14 = "sam14",
  SAM16 = "sam16",
  SAM18 = "sam18",
  SAMAero = "samAero",
}

export interface ModelBlock {
  name: string;
  displayName: string;
  optional: boolean;
  price: number;
}

export interface Model {
  id: string;
  name: string;
  description?: string;
  experimental?: boolean;
  blocks: ModelBlock[];
  pricePerSqKm: number;
  created: string;
  updated: string;
  _zoom?: any;
  zoom?: any;
}

export type ModelOption = {
  id: ModelOptionId;
  name: string;
  modelIds: string[];
  costPerKm: number;
};

export type Schema = {
  models: Model[];
  modelOptions: ModelOption[];
  maxZoomOfAll: number;
  minZoomOfAll: number;
};

interface IRaster {
  file?: File;
  aoi: Geometry;
}

export interface ITiff extends IRaster {
  type: "tiff";
}

export abstract class AbstractSchemaAPI {
  abstract getSchema(): Schema;
}

export interface ISchemaAPI {
  getSchema(): Promise<Schema>;
}
