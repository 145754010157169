import { sample } from "effector";

import {
  getInverted,
  setFalse,
  setPayload,
  setTrue,
} from "shared/lib/effector/helpers";
import { reset as resetStores } from "shared/lib/effector/reset";

import { MapStyleId } from "types";

import root from "../root";

const mapDomain = root.createDomain("map");

// EVENTS
const openMap = mapDomain.createEvent();
const closeMap = mapDomain.createEvent();

const setOverlayVisible = mapDomain.createEvent<boolean>();
const toggleOverlay = mapDomain.createEvent();

const setSidebarStep = mapDomain.createEvent<number>();
const setSidebarSourceTab = mapDomain.createEvent<number>();

const setSourceMapStyleId = mapDomain.createEvent<MapStyleId>();
const setResultsMapStyledId = mapDomain.createEvent<MapStyleId>();

const showGeocoder = mapDomain.createEvent();
const hideGeocoder = mapDomain.createEvent();
const toggleGeocoder = mapDomain.createEvent();

const showSidebar = mapDomain.createEvent();
const hideSidebar = mapDomain.createEvent();
const toggleSidebar = mapDomain.createEvent();

const setTIffFlow = mapDomain.createEvent<boolean>();

const reset = mapDomain.createEvent();

//STORES
const $isOpen = mapDomain.createStore(false);

const $isOverlayVisible = mapDomain.createStore(true);

const $isGeocoderVisible = mapDomain.createStore(false);
const $isSidebarVisible = mapDomain.createStore(true);

const $isTiffFlow = mapDomain.createStore(false);

//  sidebar
const $sidebarStep = mapDomain.createStore(0);
const $sidebarSourceTab = mapDomain.createStore(0);

const $sourceMapStyleId = mapDomain.createStore<MapStyleId>(MapStyleId.STREETS);
const $resultsMapStyleId = mapDomain.createStore<MapStyleId>(
  MapStyleId.STREETS
);

// MODEL
$isOpen.on(openMap, setTrue).on(closeMap, setFalse);

$isOverlayVisible
  .on(setOverlayVisible, setPayload)
  .on(toggleOverlay, getInverted);

$sourceMapStyleId.on(setSourceMapStyleId, setPayload);
$resultsMapStyleId.on(setResultsMapStyledId, setPayload);

$sidebarStep.on(setSidebarStep, setPayload);
$sidebarSourceTab.on(setSidebarSourceTab, setPayload);

$isTiffFlow.on(setTIffFlow, setPayload);

$isGeocoderVisible
  .on(showGeocoder, setTrue)
  .on(hideGeocoder, setFalse)
  .on(toggleGeocoder, getInverted);

$isSidebarVisible
  .on(showSidebar, setTrue)
  .on(hideSidebar, setFalse)
  .on(toggleSidebar, getInverted);

// TODO convert to combine
sample({
  source: [$sidebarSourceTab, $sidebarStep],
  clock: [setSidebarSourceTab, setSidebarStep],
  fn: ([tab, step]) => {
    console.log("SAMPLE:", { tab, step });
    return (step === 1 && tab === 2) || (step === 0 && tab === 2);
  },
  target: $isTiffFlow,
});

resetStores({
  clock: reset,
  target: [
    $isOpen,
    $isGeocoderVisible,
    $isSidebarVisible,
    $isOverlayVisible,
    $isTiffFlow,
    $sidebarStep,
    $sidebarSourceTab,
  ],
});

sample({
  clock: closeMap,
  target: reset,
});

const mapModel = {
  stores: {
    $MapIsOpen: $isOpen,
    $sidebarStep,
    $sidebarSourceTab,
    $sourceMapStyleId,
    $resultsMapStyleId,
    $isGeocoderVisible,
    $isSidebarVisible,
    $isOverlayVisible,
    $isTiffFlow,
  },
  events: {
    openMap,
    closeMap,
    //
    setSidebarStep,
    setSidebarSourceTab,
    //
    setSourceMapStyleId,
    setResultsMapStyledId,
    //
    showGeocoder,
    toggleGeocoder,
    hideGeocoder,
    //
    showSidebar,
    toggleSidebar,
    hideSidebar,
    //
    setOverlayVisible,
    toggleOverlay,
    //
    setTIffFlow,
    //
    reset,
  },
};

export default mapModel;
