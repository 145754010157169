import MapboxDraw, { TDrawOptions } from "@geoalert/mapbox-draw-gl";

import { PickCoordsMode } from "shared/lib/mapbox-draw-gl";

import type { MapboxOptions } from "mapbox-gl";

export const defaultMapOptions: Omit<MapboxOptions, "container"> = {
  style: "mapbox://styles/mapbox/streets-v11",
  center: [0, 0],
  zoom: 0,
};

export const defaultDrawOptions: TDrawOptions = {
  userProperties: true,
  displayControlsDefault: false,
  boxSelect: true,
  //@ts-expect-error
  modes: Object.assign(MapboxDraw.modes, {
    pick_coords_mode: PickCoordsMode,
  }),
};
