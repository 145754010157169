import { allSettled } from "effector";

import MapboxDraw, { TDrawOptions } from "@geoalert/mapbox-draw-gl";
import { Geometry } from "@turf/helpers";

import { drawingModel, TReason } from "models/drawing";
import wizardModel from "models/wizard";

import { finishPolygon } from "shared/lib/geo/finish-polygon";
import { validateGeometry } from "shared/lib/geojson-validation";
import { scope } from "shared/lib/hooks/use-scope";
import { LngLat } from "shared/lib/mapbox-draw-gl/types";

import { defaultDrawOptions } from "../default-options";
import { validateStyles } from "../styles";
import { TEventDrawCreate } from "../types";

export class DrawingController {
  private _map: mapboxgl.Map;
  api: MapboxDraw;

  constructor(mapAPI: mapboxgl.Map, options: TDrawOptions = {}) {
    const draw = new MapboxDraw({
      ...options,
      ...defaultDrawOptions,
      // disableEditing: true,
      canDragFeature: false,
      validation: {
        fn: (feature) => {
          const polygon = finishPolygon(feature);
          const result = validateGeometry(polygon);

          if (!result.valid && result.invalidReason)
            this.setInvalidReason(result.invalidReason);
          else this.resetInvalidReason();

          return result;
        },
        styles: validateStyles,
      },
    });

    this.api = draw;
    this._map = mapAPI;
    this._map.addControl(draw);

    this.initilizeListeners();
  }

  private changeMode<T>(mode: string, options?: T) {
    allSettled(drawingModel.setMode, { scope, params: mode });
    //@ts-expect-error
    this.api.changeMode(mode, options);
  }

  private async onModeChange({ mode }: { mode: string }) {
    this._map.getCanvas().style.cursor = "default";
    allSettled(drawingModel.setMode, { scope, params: mode });

    const featureColleaction = this.api.getAll();
    const features = featureColleaction.features;
    const isFeatures = features.length > 0;

    if (mode === "simple_select" && !isFeatures) {
      this.toViewMode();
      await allSettled(drawingModel.reset, { scope });
    }
  }

  private updateWizardAOI(geometry: Geometry) {
    allSettled(wizardModel.events.setAOI, {
      scope,
      params: geometry,
    });
  }

  private setInvalidReason(reason: TReason) {
    allSettled(drawingModel.setInvalidReason, {
      scope,
      params: reason,
    });
  }

  private resetInvalidReason() {
    allSettled(drawingModel.setInvalidReason, {
      scope,
      params: null,
    });
  }

  private onDrawCreate({ features }: TEventDrawCreate) {
    const [feature] = features;
    if (feature && feature.geometry.type === "Polygon") {
      const status = validateGeometry(feature.geometry);
      if (status.valid) {
        this.updateWizardAOI(feature.geometry);
        this.resetInvalidReason();
      } else this.clearAOI();
    }

    allSettled(drawingModel.setDrawing, { scope, params: false });
  }

  private updateDraftAOI(event: { feature: any; isDirectSelect: boolean }) {
    allSettled(drawingModel.setDraftAOI, {
      scope,
      params: event.feature.geometry,
    });
  }

  private initilizeListeners() {
    this._map.on("draw.create", (event) => this.onDrawCreate(event));
    this._map.on("draw.modechange", (event: any) => this.onModeChange(event));
    this._map.on("draw.geojson", (event) => this.updateDraftAOI(event));
    this._map.on("draw.direct-mode.error", () => this.resetInvalidReason());
    this._map.on("draw.direct-mode.stop", (event: any) =>
      this.updateWizardAOI(event.feature.geometry)
    );
  }

  toViewMode(options?: any) {
    this.changeMode("simple_select", options || { featureIds: [] });
  }

  private async resetDrawingModel() {
    await allSettled(drawingModel.setDrawing, { scope, params: false });
    await allSettled(drawingModel.setDraftAOI, { scope, params: null });
    await allSettled(drawingModel.setInvalidReason, { scope, params: null });
  }

  async cancelDrawing() {
    await this.resetDrawingModel();

    this.changeMode("simple_select");
    this._map.getCanvas().style.cursor = "default";
  }

  clearAOI() {
    this.api.deleteAll();
    allSettled(wizardModel.events.clearAOI, { scope });
    allSettled(drawingModel.setDraftAOI, { scope, params: null });
    allSettled(drawingModel.setInvalidReason, { scope, params: null });
  }

  // DRAW MODES

  startAnyDraw() {
    this.clearAOI();
    this._map.getCanvas().style.cursor = "crosshair";
    allSettled<boolean>(drawingModel.setDrawing, {
      scope,
      params: true,
    });
  }

  drawRectangle() {
    this.startAnyDraw();
    this.changeMode("draw_rectangle");
  }

  drawPolygon() {
    this.startAnyDraw();
    this.changeMode("draw_polygon");
  }

  // OTHER MODES

  async toPickCoordsMode(handleClick: (coords: LngLat) => void) {
    await this.cancelDrawing();

    this.changeMode("pick_coords_mode", {
      onSetup: () => {
        this._map.getCanvas().style.cursor = "crosshair";
      },
      onClick: (coords: LngLat) => {
        handleClick(coords);
        this._map.getCanvas().style.cursor = "default";
        this.toViewMode();
      },
    });
  }
}
