import { IUserAPI, TopUpPayload, TopUpUrl, UserInfo } from "types";

import { AxiosAPI, mock } from "./axios-api";

export class UserAPI extends AxiosAPI implements IUserAPI {
  async getUserInfo() {
    const { data } = await this.client.get<UserInfo>(`/users/me`);

    return data;
  }

  async topUp(payload: TopUpPayload) {
    const { data } = await this.client.post<TopUpUrl>(`/users/topup`, payload);

    return data;
  }

  async sync() {
    const { data } = await this.client.get("/users/sync");

    return data;
  }

  async getBillingReport(qs = "") {
    const { data } = await this.client.get("/users/billing?" + qs);

    return data;
  }
}

mock.onGet("/users/me").reply(() => {
  return [
    200,
    {
      balance: Math.floor(Math.random() * 1000),
      priceList: [
        { amount: 0, price: 0.1 },
        { amount: 5000, price: 0.075 },
        { amount: 10000, price: 0.05 },
      ],
    },
  ];
});

mock.onPost("/users/topup").reply(async () => {
  await new Promise((resolve) => setTimeout(resolve, 1000));

  return [200, { paymentUrl: "https://tinkoff.ru" }];
});
