import { AxiosAPI } from "shared/api/server/axios-api";

import { BaseProject, IProjectAPI, PaginatedData, Project } from "types";

export class ProjectAPI extends AxiosAPI implements IProjectAPI {
  async getProjects(qs?: string) {
    const { data } = await this.client.get<PaginatedData<Project>>(
      `/projects?${qs}`
    );

    return data;
  }

  async getProject(id: string) {
    const { data } = await this.client.get<Project>(`/projects/${id}`);
    return data;
  }

  async createProject(project: BaseProject) {
    const { data } = await this.client.post<Project>(`/projects`, project);
    return data;
  }

  async deleteProject(id: string) {
    await this.client.delete(`/projects/${id}`);
  }

  async updateProject(id: string, payload: BaseProject) {
    const { data } = await this.client.put<Project>(`/projects/${id}`, payload);
    return data;
  }
}
