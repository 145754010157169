import "shared/styles/globals.css";
import "models/init";

import { AppProps } from "next/app";

import { AxiosError } from "axios";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as EffectorProvider } from "effector-react/ssr";

import { ChakraProvider } from "@chakra-ui/react";

import { GoogleTagManager } from "app/scripts/google-tag-manager";
import LogRocketScript from "app/scripts/log-rocket";
import TawkScript from "app/scripts/tawk-script";

import NProgressBar from "components/nbprogress-bar";

import { InsideAppProvider } from "models/app";

import { theme } from "shared/lib/chakra";
import { useScope } from "shared/lib/hooks/use-scope";
import LanguageProvider from "shared/lib/i18n/language-provider";

import * as timeago from "timeago.js";
import ru from "timeago.js/lib/lang/ru";
import zh from "timeago.js/lib/lang/zh_CN";
import { AdminWidget } from "widgets/admin-menu";

timeago.register("ru", ru);
timeago.register("zh-CN", zh);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (_count, error) => {
        if (error && (error as AxiosError)?.response?.status === 404) {
          return false;
        }
        return true;
      },
    },
  },
});

function MyApp({ Component, pageProps }: AppProps) {
  const scope = useScope(pageProps.initialState);

  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LanguageProvider>
          <EffectorProvider value={scope}>
            <InsideAppProvider scope={scope}>
              <TawkScript />
              <GoogleTagManager.PageViewCount />
              <LogRocketScript />
              <NProgressBar
                color="#4299E1"
                height={3}
                options={{ showSpinner: false }}
              />
              <Component {...pageProps} />
              <AdminWidget />
              <ReactQueryDevtools />
            </InsideAppProvider>
          </EffectorProvider>
        </LanguageProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
}

export default MyApp;
