import { Model, Range } from "types";

// export const makeGroupModels = (models: ModelOption[]) => {
//   const byBuildings = [];
//   const others = [];
//   // sort  A -> Z
//   for (const model of models.sort((a, b) => a.name.localeCompare(b.name))) {
//     // if (model.id.includes("buildings")) {
//     //   byBuildings.push(model);
//     // } else
//     others.push(model);
//   }

//   return others;
// };

export const zoomRangeInRecord = (
  range: Range | undefined,
  allowedZoom: Record<number, boolean> | null
): boolean => {
  if (!range) return true; // if zoom not defined in schema definition
  if (!allowedZoom) return false; // if request validate zoom still pending

  for (let i = range.min; i <= (range.max || range.min); i++) {
    if (allowedZoom[i]) return true;
  }

  return false;
};

export const getZoomInRecord = (
  record: Record<number, boolean> | null,
  type: "max" | "min"
) => {
  if (!record) return -1;

  const maybeZoom = Object.entries(record)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, isIn]) => isIn)
    .sort(([a], [b]) =>
      type === "max" ? Number(b) - Number(a) : Number(a) - Number(b)
    )
    .shift();

  return maybeZoom ? Number(maybeZoom[0]) : -1;
};

export const getMinZoomInRange = (
  range: Range | undefined,
  allowedZoom: Record<number, boolean> | null
) => {
  if (!range || !allowedZoom) return 0;

  for (let i = range.min; i <= (range.max || range.min); i++) {
    if (allowedZoom[i]) return i;
  }

  return 0;
};
