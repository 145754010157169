import { ApiLimits, IWhiteMapsAPI as IMapflowWhiteMapsAPI } from "types";

import crypto from "crypto";

import { AxiosAPI, mock } from "./axios-api";

export class MapflowWhiteMapsAPI
  extends AxiosAPI
  implements IMapflowWhiteMapsAPI
{
  async getApiToken(): Promise<string> {
    const { data } = await this.client.get("/users/getapitoken");
    return data;
  }

  async getApiStats(): Promise<ApiLimits> {
    const { data } = await this.client.get("/users/apistats");
    return data;
  }

  async sendRasterFile(form: FormData): Promise<string> {
    const { data } = await this.client.post("/rasters", form, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
}

mock.onGet("/users/getapitoken").reply(() => {
  return [200, crypto.randomBytes(32).toString("hex")];
});
mock.onGet("/users/apistats").reply(() => {
  return [
    200,
    {
      login: "t.grigoryan@geoalert.io",
      processedArea: 0,
      areaLimit: 1000000000000,
      aoiAreaLimit: 10000000000,
    },
  ];
});
