import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { combine, guard, restore, sample, Scope, scopeBind } from "effector";

import root from "models/root";
import { routerReplace } from "models/router";

import { fetchUserStatus } from "shared/api/client";
import { setPayload } from "shared/lib/effector/helpers";

import { TeamRoles } from "types";

import Keycloak from "keycloak-js";
import { KeycloakConfig } from "keycloak-js";
import { combineEvents, pending } from "patronum";
import { stringify } from "query-string";

import { appStarted } from "../app";
import { clientCookies } from "./cookies";
import { KeycloakUser, MapflowRoles, PickEvent } from "./types";

export const keycloakDomain = root.createDomain("keycloak");

/**
 * updateToken(minValidity) - https://github.com/keycloak/keycloak-documentation/blob/main/securing_apps/topics/oidc/javascript-adapter.adoc
 */
export const FORCE_TOKEN_REFRESH = -1;

// Events
//  internal
const authFailed = keycloakDomain.createEvent();
const setKeycloak = keycloakDomain.createEvent<Keycloak>();
//  keycloak callback
const onReady = keycloakDomain.createEvent<PickEvent<"onReady">>();
const onAuthSuccess = keycloakDomain.createEvent<PickEvent<"onAuthSuccess">>();
const onAuthError = keycloakDomain.createEvent<PickEvent<"onAuthError">>();
const onAuthRefreshSuccess =
  keycloakDomain.createEvent<PickEvent<"onAuthRefreshSuccess">>();
const onAuthRefreshError =
  keycloakDomain.createEvent<PickEvent<"onAuthRefreshError">>();
const onAuthLogout = keycloakDomain.createEvent<PickEvent<"onAuthLogout">>();
const onTokenExpired =
  keycloakDomain.createEvent<PickEvent<"onTokenExpired">>();

// shared
export const authPageMounted = keycloakDomain.createEvent();
export const onlyForAnonymous = keycloakDomain.createEvent();
export const doLogin = keycloakDomain.createEvent();

// Effects

// const syncWithBackendFx = keycloakDomain.createEffect({
//   handler: () =>  axios.get("/api/auth/sync");
// });

export const keycloakInitFx = keycloakDomain.createEffect({
  handler: async (kc: Keycloak | null) => {
    const uri = window.location.origin + "/silent-check-sso";

    await kc?.init({
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: uri,
      enableLogging: true,
      // timeSkew: 0,
      ...clientCookies.getTokens(),
    });

    if (!kc?.authenticated) {
      throw new Error("not auth");
    }

    return kc;
  },
});

const loadUserProfileFx = keycloakDomain.createEffect({
  handler: async (kc: Keycloak | null) => {
    const profile =
      (await kc?.loadUserInfo()) as unknown as Promise<KeycloakUser>;

    const teamInfo = await fetchUserStatus();
    return {
      ...profile,
      isTeamOwner:
        (teamInfo.teams.length > 0 &&
          teamInfo.teams.find((member) => member.role === TeamRoles.OWNER)) ||
        false,
    };
  },
});

const setCookiesFx = keycloakDomain.createEffect({
  handler: async (kc: Keycloak | null) => {
    if (!kc) return;
    clientCookies.setTokens(kc);
  },
});

// Stores
export const $keycloak = keycloakDomain.createStore<Keycloak | null>(null, {
  name: "keycloak",
});

export const $keycloakUserStatus = keycloakDomain.createStore<Keycloak | null>(
  null,
  {
    name: "keycloak",
  }
);

export const $isReady = restore(onReady, false);

export const $session = keycloakDomain
  .createStore<KeycloakUser | null>(null, {
    name: "session",
  })
  .reset([authFailed]);

export const $keycloakInitilizing = combine(
  $keycloak,
  keycloakInitFx.pending,
  (kc, isPending) => {
    if (!kc) return true;
    return isPending;
  }
);

export const $sessionPending = pending({
  effects: [keycloakInitFx, loadUserProfileFx],
});

export const $user = combine({
  user: $session,
  isLoading: $sessionPending,
  error: !$isReady,
});

export const $isAdmin = $session.map((session) => {
  const maybeRoles = session?.resource_access?.mapflow?.roles;
  if (session && Array.isArray(maybeRoles))
    return maybeRoles.indexOf(MapflowRoles.Admin) > -1;

  return false;
});

export const $isCustomer = $session.map((session) => {
  const maybePermissions = session?.permissions;
  if (session && Array.isArray(maybePermissions))
    return maybePermissions.indexOf("read:results") > -1;

  return false;
});

export const $isAuthenticated = $session.map(Boolean);

const appReady = keycloakDomain.createEvent();

const phaseAuthStart = keycloakDomain.createEvent();
const phaseAuthDone = keycloakDomain.createEvent();

const phasePostAuthStart = keycloakDomain.createEvent();
const phasePostAuthDone = keycloakDomain.createEvent();

const phaseSyncingStart = keycloakDomain.createEvent();
const phaseSyncingDone = keycloakDomain.createEvent();

const phaseReadyStart = keycloakDomain.createEvent();
const phaseReadyDone = keycloakDomain.createEvent();

const firstPhase = phaseAuthStart;
const lastPhase = phaseReadyDone;

// Phase Loading

sample({
  clock: phaseAuthStart,
  source: $keycloak,
  filter: $keycloak.map((value) => value !== null),
  target: keycloakInitFx,
});

sample({
  clock: keycloakInitFx.done,
  target: phaseAuthDone,
});

//
sample({ clock: phaseAuthDone, target: phasePostAuthStart });
//

// Phase After init

sample({
  clock: phasePostAuthStart,
  source: $keycloak,
  filter: $keycloak.map((value) => value !== null),
  target: setCookiesFx,
});

sample({
  clock: setCookiesFx.done,
  target: phasePostAuthDone,
});

//
sample({ clock: phasePostAuthDone, target: phaseSyncingStart });
//

// Phase Syncing

// sample({
//   clock: phaseSyncingStart,
//   source: $keycloak,
//   target: syncWithBackendFx,
// });

// sample({
//   clock: syncWithBackendFx.done,
//   target: phaseSyncingDone,
// });

sample({
  clock: phaseSyncingStart,
  source: $keycloak,
  target: phaseSyncingDone, // Directly trigger phaseSyncingDone
});
//
sample({ clock: phaseSyncingDone, target: phaseReadyStart });
//

// Phase Ready

sample({
  clock: phaseReadyStart,
  source: $keycloak,
  filter: $keycloak.map((value) => value !== null),
  target: loadUserProfileFx,
});

sample({
  clock: loadUserProfileFx.done,
  target: phaseReadyDone,
});

const checkIdTokenFx = keycloakDomain.createEffect({
  handler: async (kc: Keycloak | null) =>
    !kc?.idToken && kc?.updateToken(FORCE_TOKEN_REFRESH),
});
sample({
  clock: phaseReadyDone,
  source: $keycloak,
  target: checkIdTokenFx,
});

// Failed phases

//

guard({
  clock: appStarted,
  filter: $isAuthenticated.map((value) => !value),
  target: firstPhase,
});

sample({
  clock: lastPhase,
  target: appReady,
});

// Logic

sample({
  clock: combineEvents({
    reset: authPageMounted,
    events: { preReadySuccess: phaseSyncingDone, onlyForAnonymous },
  }),
  source: $keycloak,
  target: routerReplace.prepend(() => ({ url: "/projects" })),
});

const tokenRefreshFx = keycloakDomain.createEffect({
  handler: async (kc: Keycloak | null) => {
    if (!kc) return;
    await kc.updateToken(FORCE_TOKEN_REFRESH);
    clientCookies.setTokens(kc);
  },
});

sample({
  clock: onTokenExpired,
  source: $keycloak,
  target: tokenRefreshFx,
});

sample({
  clock: [onAuthRefreshError, onAuthError, keycloakInitFx.fail],
  target: authFailed,
});

sample({
  clock: authFailed,
  target: routerReplace.prepend(() => ({
    url:
      window.location.pathname !== "/login"
        ? `/login?${stringify({ redirectTo: window.location.pathname })}`
        : "/login",
  })),
});

$keycloak.on(setKeycloak, setPayload);
$isReady.on(onReady, setPayload);
$session.on(loadUserProfileFx.doneData, (_, profile) => ({
  ...profile,
  name: getName(profile),
}));

// Details

export function createKeycloakInstance(scope: Scope, config: KeycloakConfig) {
  const client = new Keycloak(config);

  // set Client
  const setkeycloakHandler = scopeBind(setKeycloak, { scope });
  setkeycloakHandler(client);

  // set Callbacks to Keycloak
  const events = {
    onReady,
    onAuthSuccess,
    onAuthError,
    onAuthRefreshSuccess,
    onAuthRefreshError,
    onAuthLogout,
    onTokenExpired,
  };

  Object.entries(events).forEach(([name, event]) => {
    // @ts-expect-error
    const handler = scopeBind(event, { scope });
    // @ts-expect-error
    client[name] = handler;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error) => {
      // Refresh token if token is expired and retry
      const originalRequest = error.config;

      const is401Unauthorized =
        error.response?.status === 401 || error.response?.data?.code === "401";
      const is400BadToken =
        error.response?.status === 400 &&
        error.response?.data?.code === "BAD_TOKEN";

      if ((is401Unauthorized || is400BadToken) && !originalRequest?._retry) {
        originalRequest._retry = true;

        const result = await client.updateToken(FORCE_TOKEN_REFRESH);
        if (result) {
          clientCookies.setTokens(client);
          return axios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      request.headers.Authorization = `Bearer ${
        clientCookies.getTokens().token
      }`;
      // {
      //   // ...request.headers,
      //   Authorization: ,
      // };
      return request;
    },
    async (error) => {
      // Refresh token if token is expired and retry
      const originalRequest = error.config;

      const is401Unauthorized =
        error.response.status === 401 || error.response?.data?.code === "401";
      const is400BadToken =
        error.response.status === 400 &&
        error.response?.data?.code === "BAD_TOKEN";

      if ((is401Unauthorized || is400BadToken) && !originalRequest?._retry) {
        originalRequest._retry = true;

        const result = await client.updateToken(FORCE_TOKEN_REFRESH);
        if (result) {
          clientCookies.setTokens(client);
          return axios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  );
}

const getName = ({ email, given_name, family_name }: KeycloakUser) => {
  if (given_name && family_name) return given_name + " " + family_name;
  if (email) return email;
  return "user.name";
};
