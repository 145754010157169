import { messages as UIMessages } from "@geoalert/ui-translations";
import { AllLocaleData, AllMessages } from "@lingui/core";
import { t } from "@lingui/macro";

import { messages as msgEN } from "locales/en/messages";
import { messages as msgRU } from "locales/ru/messages";
import { messages as msgCN } from "locales/zh-CN/messages";
import { en, ru, zh } from "make-plural/plurals";

export const messages: AllMessages = {
  en: Object.assign(msgEN, UIMessages.en),
  ru: Object.assign(msgRU, UIMessages.ru),
  "zh-CN": Object.assign(msgCN, UIMessages["zh-CN"]),
};

export const plurals: AllLocaleData = {
  en: { plurals: en },
  ru: { plurals: ru },
  "zh-CN": { plurals: zh },
};

export const localeNames = {
  en: t`English`,
  ru: t`Russian`,
  "zh-CN": t`Chinese`,
};

export type Locale = keyof typeof localeNames;
export const defaultLocale: Locale = "en";

export const locales = Object.keys(localeNames) as Locale[];
