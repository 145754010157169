import * as React from "react";
import { useRouter } from "next/router";

import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";

import { defaultLocale, messages, plurals } from "./locales";

const LanguageProvider: React.FC = ({ children }) => {
  const { locale, reload } = useRouter();

  // once before render load all i18n catalogs and plurals
  React.useState(() => {
    i18n.loadLocaleData(plurals);
    i18n.load(messages);
    i18n.activate(typeof locale === "undefined" ? defaultLocale : locale);
  });

  React.useEffect(() => {
    if (i18n.locale === locale) return;
    reload();
    // i18n.activate(typeof locale === "undefined" ? defaultLocale : locale);
  }, [locale]);

  return React.createElement(I18nProvider, { i18n }, children);
};

export default LanguageProvider;
