/* eslint-disable @next/next/no-sync-scripts */
/* eslint-disable @next/next/next-script-for-ga */

import { publicRuntimeConfig } from "config";

const GTAG = publicRuntimeConfig.GOOGLE_TAG_MANAGER;
const PAVE_VIEW_EVENT = publicRuntimeConfig.GOOGLE_TAG_MANAGER__PAGE_VIEW_EVENT;

const Head = () => {
  if (!GTAG) return null;
  return (
    <>
      <script src={`https://www.googletagmanager.com/gtag/js?id=${GTAG}`} />
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GTAG}');
        `,
        }}
      ></script>
    </>
  );
};

const PageViewCount = () => {
  if (!GTAG || !PAVE_VIEW_EVENT) return null;
  return (
    <script
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `gtag('event', 'conversion', {'send_to': '${GTAG}/${PAVE_VIEW_EVENT}'});`,
      }}
    ></script>
  );
};

export const GoogleTagManager = { Head, PageViewCount };
