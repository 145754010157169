export const sample = <T>(array: Array<T>) => {
  return array[Math.floor(Math.random() * array.length)];
};

/**
 *   Create an array of certain length and set the elements within it from
 *  start value to end value.
 */
export const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

type ToggleMode = "add" | "remove" | "toggle";

/**
 *  If item exist, remove from array, otherwise add.
 * @example
 * // returns [1,2]
 * toggleOne([1, 2, 3], 3);
 * @returns {Number} Returns new array.
 */
export const toggleOne = <T>(
  array: T[],
  item: T,
  mode: ToggleMode = "toggle"
): T[] => {
  const isAdd = mode === "toggle" || mode === "add";
  const isRemove = mode === "toggle" || mode === "remove";

  const isIn = array.includes(item);

  if (isIn && isRemove) {
    return array.filter((i) => i !== item);
  }

  if (!isIn && isAdd) {
    return [...array, item];
  }

  return array;
};

/**
 *  If item from items exist, remove from array, otherwise add.
 * @example
 * // returns [1,2]
 * toggleOne([1, 2, 3], [3]);
 * @returns {Number} Returns new array.
 */
export const toggleMany = <T>(
  array: T[],
  items: T[],
  mode: ToggleMode = "toggle"
): T[] => {
  return items.reduce((acc, item) => toggleOne(acc, item, mode), array);
};

export function intersect(a: any[], b: any[]) {
  const result = [];
  const map: Record<string, any> = {};
  for (let i = 0, length = b.length; i < length; ++i) {
    map[b[i]] = true;
  }
  for (let i = 0, length = a.length; i < length; ++i) {
    if (a[i] in map) result.push(a[i]);
  }
  return result;
}
