/* eslint-disable @typescript-eslint/no-empty-function */

import { DrawCustomMode } from "@geoalert/mapbox-draw-gl";

import { LngLat, MapEventClick } from "../types";

type Options = {
  onSetup?: () => void;
  onClick?: (coords: LngLat) => void;
};

export const PickCoordsMode: Partial<DrawCustomMode<Options, Options>> = {
  onSetup: function (options) {
    if (options.onSetup) options.onSetup();
    return options;
  },
  onClick: function (state, event) {
    const e = event as unknown as MapEventClick;
    if (e.lngLat && state && state.onClick) {
      state.onClick(e.lngLat);
    }
  },
  toDisplayFeatures(_state, geojson, display) {
    display(geojson);
  },
};
