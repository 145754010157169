import { publicRuntimeConfig } from "config";

import { createDomain } from "effector";

import appConfig from "app-config";

import { debug } from "patronum";

const root = createDomain();

export const wizardDomain = root.createDomain("wizard");

if (appConfig.IS_DEVELOPMENT || publicRuntimeConfig.ENABLE_LOGGER) {
  debug(root);
}

export default root;
