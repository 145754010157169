import getConfig from "next/config";

import { getServerSetting } from "shared/lib/next/get-server-setting";

const isProduction = process.env.NODE_ENV === "production";
const isDevelopment = !isProduction;
const isTest = process.env.NODE_ENV === "test";

const testEnv = {
  publicRuntimeConfig: process.env,
  serverRuntimeConfig: process.env,
};

const { serverRuntimeConfig, publicRuntimeConfig } = isTest
  ? testEnv
  : getConfig();

export { publicRuntimeConfig, serverRuntimeConfig };

export default {
  IS_PRODUCTION: isProduction,
  IS_DEVELOPMENT: isDevelopment,

  ROLES_KEY: getServerSetting("ROLES_KEY", "https://mapflow.ai/roles"),

  APP_VERSION: process.env.APP_VERSION,
};
