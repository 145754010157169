import { HttpStatus } from "shared/api/server";
import { TAdapter } from "shared/lib/next/auth";

import { CookieSerializeOptions } from "cookie";
import Keycloak from "keycloak-js";
import nookies, { destroyCookie, parseCookies, setCookie } from "nookies";

export enum KeycloakCookies {
  TOKEN = "KEYCLOAK_TOKEN", // access token
  REFRESH_TOKEN = "KEYCLOAK_REFRESH_TOKEN",
}

const secureOptions = { path: "/", secure: true, sameSite: "lax" } as const;

function getOptions(exp: number | undefined): CookieSerializeOptions {
  if (!exp) return secureOptions;
  return { ...secureOptions, expires: new Date((exp || 0) * 1000) };
}

export const clientCookies = {
  setTokens(kc: Keycloak) {
    if (!kc.token || !kc.refreshToken) return;

    setCookie(
      null,
      KeycloakCookies.TOKEN,
      kc.token,
      getOptions(kc?.tokenParsed?.exp)
    );

    setCookie(
      null,
      KeycloakCookies.REFRESH_TOKEN,
      kc.refreshToken,
      getOptions(kc?.refreshTokenParsed?.exp)
    );
  },

  getTokens() {
    const cookies = parseCookies();
    return {
      token: cookies[KeycloakCookies.TOKEN], // same name as in keycloak.js
      refreshToken: cookies[KeycloakCookies.REFRESH_TOKEN], // same name as in keycloak.js
    };
  },

  clear() {
    destroyCookie(null, KeycloakCookies.TOKEN, secureOptions);
    destroyCookie(null, KeycloakCookies.REFRESH_TOKEN, secureOptions);
  },
};

// SSR

export const kcCookiesSSR: TAdapter = {
  async getAccessToken(req) {
    const token = nookies.get({ req })[KeycloakCookies.TOKEN];
    if (!token) {
      throw {
        code: HttpStatus.UNAUTHORIZED,
        message: "The user does not have a valid access token.",
      };
    }

    return { accessToken: token };
  },
};
