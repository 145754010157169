import { useEffect } from "react";
import { NextRouter, useRouter } from "next/router";

import { allSettled, Scope } from "effector";
import { useEvent } from "effector-react/scope";

import { keycloakConfig } from "shared/config/index";

import { sessionModel } from "./keycloak";
import root from "./root";
import { attachRouter, initializeClientHistory } from "./router";

export const appStarted = root.createEvent();

export const $appInitilized = root.createStore(false);

type OptionsApp = {
  scope: Scope;
  router: NextRouter;
};

export const initializeApp = async ({ router, scope }: OptionsApp) => {
  initializeClientHistory(router, scope);
  sessionModel.createKeycloakInstance(scope, keycloakConfig);
  return allSettled(appStarted, { scope });
};

export const InsideAppProvider: React.FC<{ scope: Scope }> = ({
  scope,
  children,
}) => {
  const attachRouterEvent = useEvent(attachRouter);
  const router = useRouter();

  useEffect(() => {
    attachRouterEvent(router);
    initializeApp({ scope, router });

    return () => (attachRouterEvent(null), void 0);
  }, [scope]);

  return <>{children}</>;
};
