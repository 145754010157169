import { NextRouter } from "next/router";

import { sample, Scope, scopeBind } from "effector";

import root from "models/root";

import { Url, UrlObject } from "url";

import { setPayload } from "../shared/lib/effector/helpers";

type TChangeRoute = {
  url: UrlObject | string;
  as?: Url;
  options?: {
    shallow?: boolean;
    locale?: string | false;
    scroll?: boolean;
    unstable_skipClientCache?: boolean;
  };
};

type TReplace = {
  router: NextRouter | null;
  options: TChangeRoute;
};

const routerDomain = root.createDomain("routerDomain");

const { createEvent, createStore, createEffect } = routerDomain;

// Events

export const attachRouter = createEvent<NextRouter | null>();
export const historyChanged = createEvent<string>();
export const routerReplace = createEvent<TChangeRoute>();

// Stores
export const $router = createStore<NextRouter | null>(null);

// Effects

export const routerReplaceFx = createEffect(
  async ({ router, options }: TReplace) => {
    if (options.url === router?.pathname) {
      return;
    }
    await router?.replace(options.url, options.as, options.options);
  }
);

export const routeChanging = routerReplaceFx.pending;

export function initializeClientHistory(router: NextRouter, scope: Scope) {
  const boundHistoryChange = scopeBind(historyChanged, { scope });
  router.events.on("routeChangeStart", (url: string) => {
    boundHistoryChange(url);
  });
}

$router.on(attachRouter, setPayload);

sample({
  source: $router,
  clock: routerReplace,
  fn: (router, options) => ({ router, options }),
  target: routerReplaceFx,
});

// const publicPages = []
