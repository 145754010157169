const adjectives = [
  "abandoned",
  "able",
  "absolute",
  "adorable",
  "adventurous",
  "academic",
  "acceptable",
  "acclaimed",
  "accomplished",
  "accurate",
  "aching",
  "acidic",
  "acrobatic",
  "active",
  "actual",
  "adept",
  "admirable",
  "admired",
  "adolescent",
  "adorable",
  "adored",
  "advanced",
  "afraid",
  "affectionate",
  "aged",
  "aggravating",
  "aggressive",
  "agile",
  "agitated",
  "agonizing",
  "agreeable",
  "ajar",
  "alarmed",
  "alarming",
  "alert",
  "alienated",
  "alive",
  "all",
  "altruistic",
  "amazing",
  "ambitious",
  "ample",
  "amused",
  "amusing",
  "anchored",
  "ancient",
  "angelic",
  "angry",
  "anguished",
  "animated",
  "annual",
  "another",
  "antique",
  "anxious",
  "any",
  "apprehensive",
  "appropriate",
  "apt",
  "arctic",
  "arid",
  "aromatic",
  "artistic",
  "ashamed",
  "assured",
  "astonishing",
  "athletic",
  "attached",
  "attentive",
  "attractive",
  "austere",
  "authentic",
  "authorized",
  "automatic",
  "avaricious",
  "average",
  "aware",
  "awesome",
  "awful",
  "awkward",
  "babyish",
  "bad",
  "back",
  "baggy",
  "bare",
  "barren",
  "basic",
  "beautiful",
  "belated",
  "beloved",
  "beneficial",
  "better",
  "best",
  "bewitched",
  "big",
  "big-hearted",
  "biodegradable",
  "bite-sized",
  "bitter",
  "black",
  "black-and-white",
  "bland",
  "blank",
  "blaring",
  "bleak",
  "blind",
  "blissful",
  "blond",
  "blue",
  "blushing",
  "bogus",
  "boiling",
  "bold",
  "bony",
  "boring",
  "bossy",
  "both",
  "bouncy",
  "bountiful",
  "bowed",
  "brave",
  "breakable",
  "brief",
  "bright",
  "brilliant",
  "brisk",
  "broken",
  "bronze",
  "brown",
  "bruised",
  "bubbly",
  "bulky",
  "bumpy",
  "buoyant",
  "burdensome",
  "burly",
  "bustling",
  "busy",
  "buttery",
  "buzzing",
  "calculating",
  "calm",
  "candid",
  "canine",
  "capital",
  "carefree",
  "careful",
  "careless",
  "caring",
  "cautious",
  "cavernous",
  "celebrated",
  "charming",
  "cheap",
  "cheerful",
  "cheery",
  "chief",
  "chilly",
  "chubby",
  "circular",
  "classic",
  "clean",
  "clear",
  "clear-cut",
  "clever",
  "close",
  "closed",
  "cloudy",
  "clueless",
  "clumsy",
  "cluttered",
  "coarse",
  "cold",
  "colorful",
  "colorless",
  "colossal",
  "comfortable",
  "common",
  "compassionate",
  "competent",
  "complete",
  "complex",
  "complicated",
  "composed",
  "concerned",
  "concrete",
  "confused",
  "conscious",
  "considerate",
  "constant",
  "content",
  "conventional",
  "cooked",
  "cool",
  "cooperative",
  "coordinated",
  "corny",
  "corrupt",
  "costly",
  "courageous",
  "courteous",
  "crafty",
  "crazy",
  "creamy",
  "creative",
  "creepy",
  "criminal",
  "crisp",
  "critical",
  "crooked",
  "crowded",
  "cruel",
  "crushing",
  "cuddly",
  "cultivated",
  "cultured",
  "cumbersome",
  "curly",
  "curvy",
  "cute",
  "cylindrical",
  "damaged",
  "damp",
  "dangerous",
  "dapper",
  "daring",
  "darling",
  "dark",
  "dazzling",
  "dead",
  "deadly",
  "deafening",
  "dear",
  "dearest",
  "decent",
  "decimal",
  "decisive",
  "deep",
  "defenseless",
  "defensive",
  "defiant",
  "deficient",
  "definite",
  "definitive",
  "delayed",
  "delectable",
  "delicious",
  "delightful",
  "delirious",
  "demanding",
  "dense",
  "dental",
  "dependable",
  "dependent",
  "descriptive",
  "deserted",
  "detailed",
  "determined",
  "devoted",
  "different",
  "difficult",
  "digital",
  "diligent",
  "dim",
  "dimpled",
  "dimwitted",
  "direct",
  "disastrous",
  "discrete",
  "disfigured",
  "disgusting",
  "disloyal",
  "dismal",
  "distant",
  "downright",
  "dreary",
  "dirty",
  "disguised",
  "dishonest",
  "dismal",
  "distant",
  "distinct",
  "distorted",
  "dizzy",
  "dopey",
  "doting",
  "double",
  "downright",
  "drab",
  "drafty",
  "dramatic",
  "dreary",
  "droopy",
  "dry",
  "dual",
  "dull",
  "dutiful",
  "each",
  "eager",
  "earnest",
  "early",
  "easy",
  "easy-going",
  "ecstatic",
  "edible",
  "educated",
  "elaborate",
  "elastic",
  "elated",
  "elderly",
  "electric",
  "elegant",
  "elementary",
  "elliptical",
  "embarrassed",
  "embellished",
  "eminent",
  "emotional",
  "empty",
  "enchanted",
  "enchanting",
  "energetic",
  "enlightened",
  "enormous",
  "enraged",
  "entire",
  "envious",
  "equal",
  "equatorial",
  "essential",
  "esteemed",
  "ethical",
  "euphoric",
  "even",
  "evergreen",
  "everlasting",
  "every",
  "evil",
  "exalted",
  "excellent",
  "exemplary",
  "exhausted",
  "excitable",
  "excited",
  "exciting",
  "exotic",
  "expensive",
  "experienced",
  "expert",
  "extraneous",
  "extroverted",
  "extra-large",
  "extra-small",
  "fabulous",
  "failing",
  "faint",
  "fair",
  "faithful",
  "fake",
  "false",
  "familiar",
  "famous",
  "fancy",
  "fantastic",
  "far",
  "faraway",
  "far-flung",
  "far-off",
  "fast",
  "fat",
  "fatal",
  "fatherly",
  "favorable",
  "favorite",
  "fearful",
  "fearless",
  "feisty",
  "feline",
  "female",
  "feminine",
  "few",
  "fickle",
  "filthy",
  "fine",
  "finished",
  "firm",
  "first",
  "firsthand",
  "fitting",
  "fixed",
  "flaky",
  "flamboyant",
  "flashy",
  "flat",
  "flawed",
  "flawless",
  "flickering",
  "flimsy",
  "flippant",
  "flowery",
  "fluffy",
  "fluid",
  "flustered",
  "focused",
  "fond",
  "foolhardy",
  "foolish",
  "forceful",
  "forked",
  "formal",
  "forsaken",
  "forthright",
  "fortunate",
  "fragrant",
  "frail",
  "frank",
  "frayed",
  "free",
  "French",
  "fresh",
  "frequent",
  "friendly",
  "frightened",
  "frightening",
  "frigid",
  "frilly",
  "frizzy",
  "frivolous",
  "front",
  "frosty",
  "frozen",
  "frugal",
  "fruitful",
  "full",
  "fumbling",
  "functional",
  "funny",
  "fussy",
  "fuzzy",
  "gargantuan",
  "gaseous",
  "general",
  "generous",
  "gentle",
  "genuine",
  "giant",
  "giddy",
  "gigantic",
  "gifted",
  "giving",
  "glamorous",
  "glaring",
  "glass",
  "gleaming",
  "gleeful",
  "glistening",
  "glittering",
  "gloomy",
  "glorious",
  "glossy",
  "glum",
  "golden",
  "good",
  "good-natured",
  "gorgeous",
  "graceful",
  "gracious",
  "grand",
  "grandiose",
  "granular",
  "grateful",
  "grave",
  "gray",
  "great",
  "greedy",
  "green",
  "gregarious",
  "grim",
  "grimy",
  "gripping",
  "grizzled",
  "gross",
  "grotesque",
  "grouchy",
  "grounded",
  "growing",
  "growling",
  "grown",
  "grubby",
  "gruesome",
  "grumpy",
  "guilty",
  "gullible",
  "gummy",
  "hairy",
  "half",
  "handmade",
  "handsome",
  "handy",
  "happy",
];

const nouns = [
  "people",
  "history",
  "way",
  "art",
  "world",
  "information",
  "map",
  "family",
  "government",
  "health",
  "system",
  "computer",
  "meat",
  "year",
  "thanks",
  "music",
  "person",
  "reading",
  "method",
  "data",
  "food",
  "understanding",
  "theory",
  "law",
  "bird",
  "literature",
  "problem",
  "software",
  "control",
  "knowledge",
  "power",
  "ability",
  "economics",
  "love",
  "internet",
  "television",
  "science",
  "library",
  "nature",
  "fact",
  "product",
  "idea",
  "temperature",
  "investment",
  "area",
  "society",
  "activity",
  "story",
  "industry",
  "media",
  "thing",
  "oven",
  "community",
  "definition",
  "safety",
  "quality",
  "development",
  "language",
  "management",
  "player",
  "variety",
  "video",
  "week",
  "security",
  "country",
  "exam",
  "movie",
  "organization",
  "equipment",
  "physics",
  "analysis",
  "policy",
  "series",
  "thought",
  "basis",
  "boyfriend",
  "direction",
  "strategy",
  "technology",
  "army",
  "camera",
  "freedom",
  "paper",
  "environment",
  "child",
  "instance",
  "month",
  "truth",
  "marketing",
  "university",
  "writing",
  "article",
  "department",
  "difference",
  "goal",
  "news",
  "audience",
  "fishing",
  "growth",
  "income",
  "marriage",
  "user",
  "combination",
  "failure",
  "meaning",
  "medicine",
  "philosophy",
  "teacher",
  "communication",
  "night",
  "chemistry",
  "disease",
  "disk",
  "energy",
  "nation",
  "road",
  "role",
  "soup",
  "advertising",
  "location",
  "success",
  "addition",
  "apartment",
  "education",
  "math",
  "moment",
  "painting",
  "politics",
  "attention",
  "decision",
  "event",
  "property",
  "shopping",
  "student",
  "wood",
  "competition",
  "distribution",
  "entertainment",
  "office",
  "population",
  "president",
  "unit",
  "category",
  "cigarette",
  "context",
  "introduction",
  "opportunity",
  "performance",
  "driver",
  "flight",
  "length",
  "magazine",
  "newspaper",
  "relationship",
  "teaching",
  "cell",
  "dealer",
  "debate",
  "finding",
  "lake",
  "member",
  "message",
  "phone",
  "scene",
  "appearance",
  "association",
  "concept",
  "customer",
  "death",
  "discussion",
  "housing",
  "inflation",
  "insurance",
  "mood",
  "woman",
  "advice",
  "blood",
  "effort",
  "expression",
  "importance",
  "opinion",
  "payment",
  "reality",
  "responsibility",
  "situation",
  "skill",
  "statement",
  "wealth",
  "application",
  "city",
  "county",
  "depth",
  "estate",
  "foundation",
  "grandmother",
  "heart",
  "perspective",
  "photo",
  "recipe",
  "studio",
  "topic",
  "collection",
  "depression",
  "imagination",
  "passion",
  "percentage",
  "resource",
  "setting",
  "ad",
  "agency",
  "college",
  "connection",
  "criticism",
  "debt",
  "description",
  "memory",
  "patience",
  "secretary",
  "solution",
  "administration",
  "aspect",
  "attitude",
  "director",
  "personality",
  "psychology",
  "recommendation",
  "response",
  "selection",
  "storage",
  "version",
  "alcohol",
  "argument",
  "complaint",
  "contract",
  "emphasis",
  "highway",
  "loss",
  "membership",
  "possession",
  "preparation",
  "steak",
  "union",
  "agreement",
  "cancer",
  "currency",
  "employment",
  "engineering",
  "entry",
  "interaction",
  "limit",
  "mixture",
  "preference",
  "region",
  "republic",
  "seat",
  "tradition",
  "virus",
  "actor",
  "classroom",
  "delivery",
  "device",
  "difficulty",
  "drama",
  "election",
  "engine",
  "football",
  "guidance",
  "hotel",
  "match",
  "owner",
  "priority",
  "protection",
  "suggestion",
  "tension",
  "variation",
  "anxiety",
  "atmosphere",
  "awareness",
  "bread",
  "climate",
  "comparison",
  "confusion",
  "construction",
  "elevator",
  "emotion",
  "employee",
  "employer",
  "guest",
  "height",
  "leadership",
  "mall",
  "manager",
  "operation",
  "recording",
  "respect",
  "sample",
  "transportation",
  "boring",
  "charity",
  "cousin",
  "disaster",
  "editor",
  "efficiency",
  "excitement",
  "extent",
  "feedback",
  "guitar",
  "homework",
  "leader",
  "mom",
  "outcome",
  "permission",
  "presentation",
  "promotion",
  "reflection",
  "refrigerator",
  "resolution",
  "revenue",
  "session",
  "singer",
  "tennis",
  "basket",
  "bonus",
  "cabinet",
  "childhood",
  "church",
  "clothes",
  "coffee",
  "dinner",
  "drawing",
  "hair",
  "hearing",
  "initiative",
  "judgment",
  "lab",
  "measurement",
  "mode",
  "mud",
  "orange",
  "poetry",
  "police",
  "possibility",
  "procedure",
  "queen",
  "ratio",
  "relation",
  "restaurant",
  "satisfaction",
  "sector",
  "signature",
  "significance",
  "song",
  "tooth",
  "town",
  "vehicle",
  "volume",
  "wife",
  "accident",
  "airport",
  "appointment",
  "arrival",
  "assumption",
  "baseball",
  "chapter",
  "committee",
  "conversation",
  "database",
  "enthusiasm",
  "error",
  "explanation",
  "farmer",
  "gate",
  "girl",
  "hall",
  "historian",
  "hospital",
  "injury",
  "instruction",
  "maintenance",
  "manufacturer",
  "meal",
  "perception",
  "pie",
  "poem",
  "presence",
  "proposal",
  "reception",
  "replacement",
  "revolution",
  "river",
  "son",
  "speech",
  "tea",
  "village",
  "warning",
  "winner",
  "worker",
  "writer",
  "assistance",
  "breath",
  "buyer",
  "chest",
  "chocolate",
  "conclusion",
  "contribution",
  "cookie",
  "courage",
  "desk",
  "drawer",
  "establishment",
  "examination",
  "garbage",
  "grocery",
  "honey",
  "impression",
  "improvement",
  "independence",
  "insect",
  "inspection",
  "inspector",
  "king",
  "ladder",
  "menu",
  "penalty",
  "piano",
  "potato",
  "profession",
  "professor",
  "quantity",
  "reaction",
  "requirement",
  "salad",
  "sister",
  "supermarket",
  "tongue",
  "weakness",
  "wedding",
  "affair",
  "ambition",
  "analyst",
  "apple",
  "assignment",
  "assistant",
  "bathroom",
  "bedroom",
  "beer",
  "birthday",
  "celebration",
  "championship",
  "cheek",
  "client",
  "consequence",
  "departure",
  "diamond",
  "dirt",
  "ear",
  "fortune",
  "friendship",
  "funeral",
  "gene",
  "girlfriend",
  "hat",
  "indication",
  "intention",
  "lady",
  "midnight",
  "negotiation",
  "obligation",
  "passenger",
  "pizza",
  "platform",
  "poet",
  "pollution",
  "recognition",
  "reputation",
  "shirt",
  "speaker",
  "stranger",
  "surgery",
  "sympathy",
  "tale",
  "throat",
  "trainer",
  "uncle",
  "youth",
  "time",
  "work",
  "film",
  "water",
  "money",
  "example",
  "while",
  "business",
  "study",
  "game",
];

export { adjectives, nouns };
