/**
 * Processings
 */

import { Aoi, MaybeNull, SourcePayload, UrlType } from "types";
import { ITiff, ModelId, ModelOptionId } from "types/model";

import { CrsValue } from "./wizard";

export interface IProcessingAPI {
  // getProcessings(projectId: string): Promise<Processing[]>;

  // createProcessing(payload: ProcessingBackend): Promise<Processing>;

  updateProcessing(id: string, payload: ProcessingUpdate): Promise<Processing>;
}
export const MARGIN_SIZE = 12;
export const DEFAULT_CARD_HEIGHT = 123;
export const DEFAULT_FAILED_CARD_HEIGHT = 199;
export const OFFSET_FROM_TO_TOP_ELEMENT = 254;
export const READONLY_DEFAULT_FAILED_CARD_HEIGHT = 123;
export const OPENED_DEFAULT_CARD_HEIGHT = 272;
export const OPENED_DEFAULT_CARD_HEIGHT_WITH_BLOCKS = 297;

export enum ProcessingStatus {
  PENDING = <any>"PENDING",
  INPROGRESS = <any>"IN_PROGRESS",
  OK = <any>"OK",
  FAILED = <any>"FAILED",
}

export type ProcessingCost = { cost: number };

export enum StageName {
  "dataloader" = "dataloader",
  "validate-source" = "validate-source",
  "inference" = "inference",
  "build-cog" = "build-cog",
  "select-source" = "select-source",
  "import-vector" = "import-vector",
  "user-input" = "user-input",
}

export type Stage = {
  name: StageName;
  description: string;
  status: ProcessingStatus;
};

export interface ProcessingPayload {
  name: string;
  wdId: string;
  projectId: string;
  blocks: any[];
  geometry: Aoi;
  description?: string;
  source?: SourcePayload;
  aoi?: Aoi;
  model?: ModelId;
  modelOptionsIds?: ModelOptionId[];
  file?: any;
  meta?: {
    test: string;
  };
  // TODO
  raster?: ITiff | null;
  params?: ProcessingParams;
}

export type TProcessingMessage = {
  code: string;
  parameters: Record<string, string | number | boolean>;
};

export interface ProcessingBackendRaw {
  id: string;
  cost: number;
  created: string;
  stars: ProcessingRating;
  projectId: string;
  name: string;
  description?: string;
  workflow: {
    id: number;
    status: ProcessingStatus;
    stages: Stage[];
    vectorLayer: {
      layerId: string;
      uri: string;
    };
    rasterLayer: {
      uri?: string;
    };
  };
  config: {
    rasterSource: {
      type: UrlType.XYZ | "tif";
      url: string;
      yOriginTop: boolean;
      zoom: number;
      crs: CrsValue;
    };
    aoi: {
      geometry: Aoi;
    };
    modelId: ModelId;
    optionIds: ModelOptionId[];
  };
  messages?: TProcessingMessage[];
}

type StaticProperties = "workflow" | "stars" | "cost" | "created" | "id";

export type ProcessingBackend = Omit<ProcessingBackendRaw, StaticProperties>;

export type ProcessingRating = 0 | 1 | 2 | 3 | 4 | 5;

export interface ProcessingParams {
  url?: string;
  source_type?: string;
  data_provider?: string;
}

export interface Processing {
  id: string;
  projectId: string;
  name: string;
  description: MaybeNull<string>;
  created: string;
  updated: string;
  vectorLayer: any;
  rasterLayer?: any;
  status: ProcessingStatus;
  cost: number;
  progress: {
    stages: Stage[];
  };
  stars: ProcessingRating;
  rating: { rating: number; feedback: string | null };
  messages: TProcessingMessage[];
  blocks: any[];
  workflowDef: any;
  percentCompleted: number | null;
  params: ProcessingParams;
  config?: any;
  area: number;
  aoiArea: number;
}

export interface ProcessingAoi {
  id: string;
  geometry: Aoi;
  area: number;
  percentCompleted: number;
  status: ProcessingStatus;
}

export type ProcessingUpdate = Partial<
  Pick<Processing, "name" | "description">
>;
