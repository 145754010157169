import clone from "@turf/clone";
import { Geometry } from "@turf/helpers";

export function finishPolygon(geometry: Geometry) {
  const clonedGeometry = clone(geometry);
  const coords = clonedGeometry.coordinates[0];
  if (Array.isArray(coords)) {
    // if cursor === vertex delete
    const cursor = coords[coords.length - 1];
    const lastPoint = coords[coords.length - 2];

    if (cursor && lastPoint) {
      if (cursor[0] === lastPoint[0] && cursor[1] === lastPoint[1]) {
        clonedGeometry.coordinates[0].pop();
      }
    }

    if (coords[0] !== coords[coords.length - 1]) {
      clonedGeometry.coordinates[0].push(coords[0]);
    }
  }

  return clonedGeometry;
}
