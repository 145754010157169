import { Geometry } from "@turf/helpers";

import root from "models/root";

import { reset as resetStores } from "shared/lib/effector/reset";
import { calculateArea } from "shared/lib/geo/calculate-area";
import { InvalidReason } from "shared/lib/geojson-validation";

import { MaybeNull } from "types";

export type TReason = MaybeNull<InvalidReason>;

const domain = root.createDomain("drawing");

// Events
const setInvalidReason = domain.createEvent<TReason>();
const setDrawing = domain.createEvent<boolean>();
const setDraftAOI = domain.createEvent<Geometry | null>();
const setMode = domain.createEvent<string>();

const reset = domain.createEvent();

// Stores
const $mode = domain.createStore("simple-select");
const $drawing = domain.createStore(false);
const $draftAOI = domain.createStore<Geometry | null>(null);

const $invalidReason = domain.createStore<TReason>(null);

const $isValid = $invalidReason.map((reason) => reason === null);

// BLL
$mode.on(setMode, (_, mode) => mode);
$drawing.on(setDrawing, (_, isDrawing) => isDrawing);
$draftAOI.on(setDraftAOI, (_, draftAOI) => draftAOI);
$invalidReason.on(setInvalidReason, (_, reason) => reason);

resetStores({
  clock: [reset],
  target: [$mode, $drawing, $draftAOI, $invalidReason],
});

const $modeAssertion = $mode.map((mode) => ({
  isSimpleSelect: mode === "simple_select",
  isDrawRectangle: mode === "draw_rectangle",
  isPickCoordsMode: mode === "pick_coords_mode",
  isDrawPolygon: mode === "draw_polygon",
  isDirectSelect: mode === "direct_select",
}));

const $isEditing = $modeAssertion.map(({ isDirectSelect }) => isDirectSelect);

const $drawingArea = $draftAOI.map((aoi) => {
  if (aoi) {
    return calculateArea(aoi);
  }

  return 0;
});

export const drawingModel = {
  $drawing,
  $invalidReason,
  $isValid,
  $draftAOI,
  $drawingArea,
  $modeAssertion,
  $isEditing,
  //
  setMode,
  setInvalidReason,
  setDrawing,
  setDraftAOI,
  //
  reset,
};
