import { combine, guard, sample } from "effector";

import { USER_RASTER_ID } from "components/map/const";
import MapboxAPI from "components/map/model/instance";

import { drawingModel } from "models/drawing";
import mapModel from "models/map";

import { setLayerVisibility } from "shared/lib/mapbox/toggle-layer-visibility";

guard({
  clock: drawingModel.$modeAssertion,
  filter: ({ isPickCoordsMode, isDrawRectangle }) =>
    isPickCoordsMode || isDrawRectangle,
  target: mapModel.events.hideGeocoder,
});

sample({
  clock: guard({
    clock: drawingModel.$drawing,
    filter: Boolean,
  }),
  fn: () => 1,
  target: mapModel.events.setSidebarStep,
});

sample({
  clock: mapModel.events.closeMap,
  target: drawingModel.reset,
});

// sample({
//   source: wizardModel.stores.$aoi,
//   clock: [drawingModel.$drawing, wizardModel.stores.$aoi],
//   fn: (aoi, isDrawing) => {
//     if (!isDrawing && aoi) {
//       MapboxAPI.addSingleAOI(aoi);
//     }

//     return null;
//   },
// });

// REGION START

// When geocoder is visible reset drawing model and switch to view mode
guard({
  clock: mapModel.stores.$isGeocoderVisible,
  filter: Boolean,
  target: drawingModel.reset,
});

// If in draft mode and activate geomode, turn off drawing
combine([mapModel.stores.$isGeocoderVisible, drawingModel.$drawing]).watch(
  ([isGeoVisible, isDrawing]) => {
    if (isGeoVisible && isDrawing) {
      MapboxAPI.drawingController?.cancelDrawing();
    }
  }
);

// REGION END

mapModel.stores.$isOverlayVisible.watch((isVisible) => {
  if (MapboxAPI.map) {
    setLayerVisibility(MapboxAPI.map, USER_RASTER_ID, isVisible);
  }
});
