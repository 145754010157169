import { useMemo } from "react";
import Head from "next/head";

import { publicRuntimeConfig } from "app-config";

import { useUser } from "models/keycloak";

function TawkScript() {
  const { user, isLoading } = useUser();

  const { email, name } = user || {};

  const __html = useMemo(
    () => `
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    Tawk_API.visitor = {
      name: "${name}",
      email: "${email}"
    };
    (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='${publicRuntimeConfig.TAWK_URL}';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
  })();`,
    [email, name]
  );

  if (!isLoading && user)
    return (
      <>
        <Head>
          <script type="text/javascript" dangerouslySetInnerHTML={{ __html }} />
        </Head>
      </>
    );

  return null;
}

export default TawkScript;
