import { TDrawOptions } from "@geoalert/mapbox-draw-gl";
import bbox from "@turf/bbox";
import { Feature, Geometry } from "@turf/helpers";

import type { AnySourceData, PopupOptions } from "mapbox-gl";

import { DrawingController } from "./drawing-controller";
import { isCoords } from "./lib";
import { MapOptions } from "./types";

class Mapbox {
  private _map?: mapboxgl.Map;

  drawingController?: DrawingController;

  get map() {
    return this._map;
  }

  cleanup() {
    if (this._map) {
      this._map.remove();
      this._map = undefined;
    }

    if (this.drawingController) {
      this.drawingController = undefined;
    }
  }

  async create<T extends HTMLElement>(container: T, options: MapOptions) {
    const mapboxgl = await import("mapbox-gl");

    const mapboxMap = new mapboxgl.Map({
      container,
      ...options,
    });

    if (typeof window !== undefined) {
      window.mapboxAPI = MapboxAPI;
    }

    this._map = mapboxMap;

    return { supported: mapboxgl.supported() };
  }

  async Popup(options: PopupOptions) {
    const mapboxgl = await import("mapbox-gl");
    return new mapboxgl.Popup(options);
  }

  createDraw(options: TDrawOptions = {}) {
    if (this._map && !this.drawingController) {
      this.drawingController = new DrawingController(this._map, options);
    }
  }

  fitBounds(
    feature: Feature | Geometry,
    fitOptions?: mapboxgl.FitBoundsOptions
  ) {
    if (this._map) {
      const bounds = bbox(feature);
      if (bounds.length === 4) {
        this._map.fitBounds(bounds, fitOptions);
      }
    }
  }

  /**
   * Accepter format coordinate - [lng, lat]
   */
  jumpTo(coordinate: [number, number]) {
    try {
      if (this._map && Array.isArray(coordinate) && coordinate.length === 2) {
        this._map.jumpTo({
          center: { lng: coordinate[0], lat: coordinate[1] },
          zoom: 10,
        });
      }
    } catch (error) {}
  }

  flyTo(
    coordinate: [number, number],
    onFly?: () => void,
    onError?: () => void
  ) {
    try {
      if (this._map && isCoords(coordinate)) {
        if (onFly) onFly();
        this._map.flyTo({
          center: { lat: coordinate[0], lng: coordinate[1] },
          zoom: 15,
          essential: true, // this animation is considered essential with respect to prefers-reduced-motion
        });
      }
    } catch (error) {
      if (onError) onError();
    }
  }

  addSingleAOI(geometry: Geometry, fitIn = false) {
    if (this.drawingController) {
      this.drawingController.api.deleteAll();
      // @ts-expect-error
      this.drawingController.api.add(geometry);

      if (fitIn) {
        this.fitBounds(geometry, {
          padding: 100,
          duration: 300,
        });
      }
    }
  }

  addSource(sourceId: string, source: AnySourceData) {
    try {
      this._map?.addSource(sourceId, source);
    } catch {}

    return this;
  }

  addLayer(layer: mapboxgl.LineLayer, before?: string) {
    try {
      this._map?.addLayer(layer, before);
    } catch {}

    return this;
  }
}

const MapboxAPI = new Mapbox();

export default MapboxAPI;
