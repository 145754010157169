import { useStore } from "effector-react/scope";

import { sessionModel } from "models/keycloak";
import wizardModel from "models/wizard";

import { Role } from "types";

export const useUser = () => useStore(sessionModel.$user);
export const useIsAdmin = () => useStore(sessionModel.$isAdmin);
export const useCanDownloadResults = () => useStore(sessionModel.$isCustomer);

export const useSharedUser = () => useStore(wizardModel.stores.$sharedUserRole);

export const useIsReadonly = () =>
  useStore(wizardModel.stores.$sharedUserRole) === Role.readOnly;

export const useIsContributor = () =>
  useStore(wizardModel.stores.$sharedUserRole) === Role.contributor;

export const useIsMaintainer = () =>
  useStore(wizardModel.stores.$sharedUserRole) === Role.maintainer;
