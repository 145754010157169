export enum SortBy {
  name = "NAME",
  created = "CREATED",
  updated = "UPDATED",
}

export enum SortOrder {
  asc = "ASC",
  desc = "DESC",
}
