import { useMemo } from "react";

import { fork, Scope, serialize } from "effector";

export let scope: Scope;

function initializeScope(initialData?: any): Scope {
  const _scope =
    scope ??
    fork({
      values: {
        ...(scope ? serialize(scope) : {}),
        ...initialData,
      },
    });

  // For SSG and SSR always create a new scope
  if (typeof window === "undefined") {
    return _scope;
  }
  // Create the scope once in the client

  if (!scope) {
    scope = _scope;
  }

  return _scope;
}

export const getScope = (initialState?: any) => {
  return initializeScope(initialState);
};

export function useScope(initialState: any): Scope {
  return useMemo(() => initializeScope(initialState), [initialState]);
}
