import { MaybeNull } from "types";

export interface FeatureValidationResult {
  valid: boolean;
  invalidReason: MaybeNull<InvalidReason>;
}

export enum InvalidReason {
  UnknownError = "unknown-error",
  MaxArea = "max-area",
  MinArea = "min-area",
  Size = "size",
  SelfIntersection = "self-intersection",
  EmptyArea = "empty-area",
  MethodError = "method-error",
  OutOfTiff = "out-of-tiff",
}
