import type Keycloak from "keycloak-js";

type EventName =
  | "onReady"
  | "onAuthSuccess"
  | "onAuthError"
  | "onAuthRefreshSuccess"
  | "onAuthRefreshError"
  | "onAuthLogout"
  | "onTokenExpired";

/**
 *  @description Get first type of parameter from keycloak event
 */
export type PickEvent<T extends EventName> = NonNullable<Keycloak[T]> extends (
  ...args: infer P
) => any
  ? P[0] extends undefined
    ? void
    : P[0]
  : any;

export interface UserRepresentation {
  id?: string;
  createdTimestamp?: number;
  username?: string;
  enabled?: boolean;
  totp?: boolean;
  emailVerified?: boolean;
  disableableCredentialTypes?: string[];
  access?: Record<string, boolean>;
  // optional from response
  attributes?: Record<string, unknown>;
  clientRoles?: Record<string, any>;
  email?: string;
  firstName?: string;
  groups?: string[];
  lastName?: string;
  origin?: string;
  realmRoles?: string[];
}

export interface IAuthRedirectProps<T> {
  /**
   * URL used to redirect a user to
   * By default, if only this prop is set, the component will redirect if no session is found
   */
  to: string;
  /**
   * If this property is set to `true`, user will be redirected if he is logged in.
   * Useful when you don't want to show login page to already logged in users.
   *
   * Will be ignored if `condition` is defined.
   */
  ifFound?: boolean;
  /**
   * Callback that will trigger a redirect if true is returned.
   * Useful when you need to redirect base on some attribute, e.g. if user is not admin
   */
  condition?(session: T): boolean;
}

// User
export interface KeycloakUser {
  sub: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
  resource_access: ResourceAccess;
  logins_count?: number;
  locale?: string;
  picture?: string;
  isTeamOwner: boolean;
  permissions: [string];
  company?: string;
  country?: string;
  application?: string;
  gis_exp?: string;
  role: string;
}

type Roles<T = string> = { roles: T[] };

export enum MapflowRoles {
  Admin = "admin",
  User = "user",
}

export interface ResourceAccess {
  mapflow: Roles<MapflowRoles>;
  account: Roles;
}
