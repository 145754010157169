import dynamic from "next/dynamic";

import { useIsAdmin } from "models/keycloak";

const DynamicAdminDrawer = dynamic(async () => {
  return (await import("./admin-drawer")).AdminDrawer;
});

export const AdminWidget = () => {
  const isAdmin = useIsAdmin();
  if (isAdmin) return <DynamicAdminDrawer />;
  return null;
};
