export const isS3 = (url: string) => (url ? url?.startsWith("s3") : false);

export type Prefixed<T extends Record<string, unknown>, P extends string> = {
  [K in `${P}${string & keyof T}`]: T[keyof T];
};
/**
 * @param attributes Object of attributes to prefix
 * @param prefix Prefix to add to each attribute
 * @returns Object of attributes with prefix added to each key
 */
export const prefixAttributes = <
  T extends Record<string, T[keyof T]>,
  P extends string
>(
  attributes: T,
  prefix: P
): Prefixed<T, P> => {
  const prefixedAttributes = {} as Prefixed<T, P>;
  Object.keys(attributes).forEach((key) => {
    prefixedAttributes[`${prefix}${key}` as keyof Prefixed<T, P>] =
      attributes[key];
  });

  return prefixedAttributes;
};

export type Unprefixed<
  T extends Record<string, unknown>,
  P extends string
> = Record<keyof T extends `${P}${infer U}` ? U : never, T[keyof T]>;
/**
 * @param attributes Object of attributes to remove prefix from
 * @param prefix Prefix to remove from each attribute
 * @returns Object of attributes with prefix removed from each key
 */
export const unprefixAttributes = <T extends Record<string, unknown>>(
  attributes: Record<string, unknown>,
  prefix: string
): Unprefixed<Prefixed<T, typeof prefix>, typeof prefix> => {
  const unprefixedAttributes = {} as Unprefixed<
    Prefixed<T, typeof prefix>,
    typeof prefix
  >;

  Object.keys(attributes).forEach((key) => {
    const newKey = (
      key.startsWith(prefix) ? key.replace(prefix, "") : key
    ) as keyof typeof unprefixedAttributes;
    unprefixedAttributes[newKey] = attributes[key] as T[keyof T];
  });

  return unprefixedAttributes;
};
