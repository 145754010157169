import schemaFixture from "shared/mocks/schema.json";

import { ISchemaAPI, Schema } from "types";

import { AxiosAPI, mock } from "./axios-api";

export class SchemaAPI extends AxiosAPI implements ISchemaAPI {
  async getSchema() {
    const { data } = await this.client.get<Schema>("/schema");

    return data;
  }
}

mock.onGet("/schema").reply(200, schemaFixture);
