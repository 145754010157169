import root from "models/root";

const domain = root.createDomain("mapAPI");

const $mapLoaded = domain.createStore(false);

const setMapLoaded = domain.createEvent<boolean>();
$mapLoaded.on(setMapLoaded, (_, isLoaded) => isLoaded);

export const mapboxModel = {
  $mapLoaded,
  setMapLoaded,
};
