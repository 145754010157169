export function getServerSetting(
  environmentVariable: string,
  defaultValue?: any
) {
  if (typeof window === "undefined") {
    return process.env[environmentVariable] ?? defaultValue;
  }

  return defaultValue ?? null;
}
