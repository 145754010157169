import { useEffect } from "react";
import config, { publicRuntimeConfig } from "config";

import { useUser } from "models/keycloak";

import { Maybe } from "types";

import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

type AcceptableTypes = string | boolean | number;

function tryPick<T>(
  func: () => Maybe<AcceptableTypes | T>,
  fallbackValue: AcceptableTypes = "undefined"
) {
  try {
    const value = func();
    return value === null || value === undefined ? fallbackValue : value;
  } catch (e) {
    return fallbackValue;
  }
}

const isCanInitialize = config.IS_PRODUCTION && typeof window !== "undefined";

if (isCanInitialize) {
  LogRocket.init(publicRuntimeConfig.LOG_ROCKET_APP_ID);
  setupLogRocketReact(LogRocket);
}

const LogRocketScript = () => {
  const { user } = useUser();

  useEffect(() => {
    if (isCanInitialize) {
      if (user) {
        console.log("user id:", user?.sub);

        if (user.sub) {
          LogRocket.identify(user.sub, {
            name: tryPick<string>(() => user.name),
            email: tryPick<string>(() => user.email),

            // my custom fields
            emailVerified: tryPick<string>(() => user.email_verified),
            locale: tryPick<string>(() => user.locale),
            roles: JSON.stringify(tryPick(() => user.resource_access)),
          });
        }
      }
    }
  }, [user]);

  return null;
};

export default LogRocketScript;
