export function getLayerVisibility(
  mapAPI: mapboxgl.Map | undefined = undefined,
  layerId: string
) {
  if (!mapAPI || !mapAPI.getLayer(layerId)) return;

  return mapAPI.getLayoutProperty(layerId, "visibility");
}

export function toggleLayerVisibility(mapAPI: mapboxgl.Map, layerId: string) {
  if (!mapAPI || !mapAPI.getLayer(layerId)) return;

  const visibility = mapAPI.getLayoutProperty(layerId, "visibility");

  // Toggle layer visibility by changing the layout object's visibility property.
  if (visibility === "visible") {
    mapAPI.setLayoutProperty(layerId, "visibility", "none");
  } else {
    mapAPI.setLayoutProperty(layerId, "visibility", "visible");
  }
}

export function setLayerVisibility(
  mapAPI: mapboxgl.Map,
  layerId: string,
  isVisible: boolean
) {
  if (!mapAPI || !mapAPI.getLayer(layerId)) return;
  mapAPI.setLayoutProperty(
    layerId,
    "visibility",
    isVisible ? "visible" : "none"
  );
}
