import { Geometry } from "@turf/helpers";

export enum UrlType {
  XYZ = "xyz", // bottom left
  TMS = "tms", // top left
}

export enum Crs {
  WORLD_MERCATOR = "espg:3395",
  WEB_MERCATOR = "espg:3857",
}

export enum CrsValue {
  WORLD_MERCATOR = "3395",
  WEB_MERCATOR = "3857",
}

export type SourcePayload = {
  type: UrlType | "tif" | string;
  url: string;
  zoom?: number;
  crs?: CrsValue;
  name?: string;
  displayName?: string;
  data_provider?: string;
  description?: string | undefined;
};

// key of default source, used for select image tile
export type SourceTypes = "mapbox" | "arcgis";
export type DefaultSources = Record<string, SourcePayload>;

export enum MapStyleId {
  DARK = "dark-v10",
  LIGHT = "light-v10",
  STREETS = "streets-v11",
  SATELLITE = "satellite-v9",
}

export type Aoi = Geometry;
