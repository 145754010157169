import { publicRuntimeConfig } from "config";

import { KeycloakConfig } from "keycloak-js";

export const keycloakConfig: KeycloakConfig = {
  url: publicRuntimeConfig.KC_URL,
  realm: publicRuntimeConfig.KC_REALM,
  clientId: publicRuntimeConfig.KC_OIDC_CLIENT_ID,
};

export const userManagementClientConfig = {
  grant_type: "client_credentials",
  client_id: publicRuntimeConfig.KC_MAN_CLIENT_ID,
  client_secret: publicRuntimeConfig.KC_MAN_CLIENT_SECRET,
};
