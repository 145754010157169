import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  radii: {
    "ui-base": "15px",
  },
  fonts: {
    body: "'Nunito', system-ui, sans-serif",
    heading: "'Nunito', Georgia, serif",
    mono: "Menlo, monospace",
  },
  space: {
    "7": "1.75rem",
  },
  fontSizes: {
    lg: "1rem",
    small: "0.8rem",
    smaller: "0.7rem",
  },
  components: {
    Button: {
      sizes: {
        lg: {
          fontSize: "0.875rem",
          lineHeight: "21px",
        },
      },
    },
  },
});
